import { Suspense } from "react";
import { createGlobalStyle } from 'styled-components'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Layout from "./components/Layout";
import Spinner from "./components/Spinner";
import Overview from "./views/Overview";
import Reports from "./views/Reports";
import ReportsDetail from "./views/ReportsDetail";

import PrivateRoute from "./components/PrivateRoute";

import { MAP, LISTINGS, CHARGES, REPORTS, REPORTS_ID, CHARGERS, SESSIONS } from "./constants/routeConstants";
import { BREAKPOINTS } from "styles/breakpoints";
import Chargers from "views/Chargers";

const env = process.env.REACT_APP_BUILD_VERSION;
const GlobalStyle = createGlobalStyle`
  body {
   background: #F5F5F5;
  }
`

const StyledApp = styled.div`
  padding: 24px;

  @media(min-width: ${BREAKPOINTS.MEGA}px) {
    padding: 40px 64px;
  }
`;

export const rootUrl = () =>
  env === "development"
    ? "http://localhost:8080"
    : `https://${env === "staging" ? "app-staging" : "app"}.evmatch.com`;

function App() {
  return (
    <StyledApp>
      <GlobalStyle />
      <Layout>
        <Suspense fallback={Spinner}>
          <BrowserRouter>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={Overview}
                roles={["superadmin", "admin"]}
              />
              <PrivateRoute
                exact
                path={REPORTS}
                component={Reports}
                roles={["superadmin", "admin"]}
              />
              <PrivateRoute
                exact
                path={SESSIONS}
                component={ReportsDetail}
                roles={["superadmin", "admin"]}
              />
              <Route
                path={CHARGERS}
                component={Chargers}
              />
              <Route
                path={MAP}
                component={() => {
                  window.location.replace(`${rootUrl()}/s`);
                  return null;
                }}
              />
              <Route
                path={LISTINGS}
                component={() => {
                  window.location.replace(`${rootUrl()}/my-listing/edit`);
                  return null;
                }}
              />
              <Route
                path={CHARGES}
                component={() => {
                  window.location.replace(`${rootUrl()}/charges/user-charges`);
                  return null;
                }}
              />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </Layout>
    </StyledApp>
  );
}

export default App;
