import * as React from 'react';
import { BaseText } from './styles';
import { BaseTextProps } from './constants';
import { Spacing } from '../primitives';

export type BodyTextSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

interface BodyProps extends BaseTextProps {
  size?: BodyTextSize;
}

function getBodyStyles(size: BodyTextSize): BaseTextProps {
  switch (size) {
    case 'xl': {
      return {
        fontSize: '26px',
        lineHeight: '22px',
      };
    }
    case 'lg': {
      return {
        fontSize: '18px',
        lineHeight: '28px',
      };
    }
    case 'md': {
      return {
        fontSize: '16px',
        lineHeight: Spacing.scale[3],
      };
    }
    case 'sm': {
      return {
        fontSize: '14px',
        lineHeight: '20px',
      };
    }
    case 'xs': {
      return {
        fontSize: '12px',
        lineHeight: '20px',
      };
    }
    default: {
      throw new Error(`Unknown Text.Body size prop: ${size}`);
    }
  }
}

const BodyText = React.forwardRef<HTMLParagraphElement, BodyProps>(
  ({ size = 'md', as = 'p', ...restProps }: BodyProps, ref) => (
    <BaseText ref={ref} as={as} {...getBodyStyles(size)} {...restProps} />
  )
);

export default BodyText;
