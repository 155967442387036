import * as React from 'react';
import { BaseText } from '../../../common/design-system/Text/styles';
import BodyText from './BodyText';
import HeadingText from './HeadingText';
import LinkText from './LinkText';
import SubheadingText from './SubheadingText';
import { BaseTextProps } from './constants';

export * from './constants';

function Text(props: BaseTextProps) {
  return <BaseText {...props} />;
}

Text.Heading = HeadingText;
Text.Body = BodyText;
Text.Link = LinkText;
Text.Subhead = SubheadingText;
export { Text };
