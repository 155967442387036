const env = process.env.REACT_APP_BUILD_VERSION;
export const getBasePath = () =>
  env === "development"
    ? `https://${env === "development" ? "test-ocpp" : "ocpp"}.evmatch.com/api/dashboard`
    //: `http://${env === "local" ? "localhost:3001" : "ocpp"}/api/dashboard`
    : `https://${env === "staging" ? "test-ocpp" : "ocpp"}.evmatch.com/api/dashboard`;

    export const getBasePathOcpi = () =>
    env === "development"
      ? `https://evmatch-api${env === "development" ? "-staging" : ""}.herokuapp.com/dashboard`
      //: `http://${env === "local" ? "localhost:3001" : "ocpp"}/api/dashboard`
      : `https://evmatch-api${env === "staging" ? "-staging" : ""}.herokuapp.com/dashboard`;