import styled from 'styled-components';
import { BaseTextProps } from './constants';
import { shorthandSpacingCss } from '../primitives/Spacer';

const getFontWeight = (fontWeight, bold) => {
  if (fontWeight) {
    return fontWeight;
  }

  if (bold) {
    return 800;
  }

  return 400;
};

export const BaseText = styled('p')<BaseTextProps>`
  font-weight: ${({ fontWeight, bold }) => getFontWeight(fontWeight, bold)};
  font-style: ${({ italic }) => (italic ? 'italic' : 'inherit')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'inherit')};
  text-align: ${({ align, center }) => (center ? 'center' : align || 'inherit')};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing = 0 }) => letterSpacing};
  margin: ${({ margin }) => margin || 0};
  color: ${({ color }) => color || 'var(--primary)'};
  ${({ numeric }) => numeric && "font-feature-settings: 'tnum' on, 'lnum' on;"}
  ${shorthandSpacingCss}
`;

export const BaseLinkText = styled(BaseText)`
  text-decoration: none;
  text-underline-position: under;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }
`;
