/* tslint:disable */
/* eslint-disable */
/**
 * EVMatch OCPP API
 * This is the EVmatch OCPP dashboard api spec.  You can find out more about     Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/).
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: greg@gcdev.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Charger
 */
export interface Charger {
    /**
     * 
     * @type {number}
     * @memberof Charger
     */
    chargerId?: number;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    chargePointIdentity?: string;
    /**
     * 
     * @type {ChargerStatuses}
     * @memberof Charger
     */
    status?: ChargerStatuses;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    statusTimestamp?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Charger
     */
    chargingVcrId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    address_line1?: string;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    stickerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Charger
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface ChargerNotFoundError
 */
export interface ChargerNotFoundError {
    /**
     * description of the error message
     * @type {string}
     * @memberof ChargerNotFoundError
     */
    error?: string;
}
/**
 * Only \"Online\", \"Offline\", \"NeverConnected\", \"Unknown\" are non-compliant OCPP 1.6 ChargePointStatuses.  All other statues are OCPP 1.6 compliant.
 * @export
 * @enum {string}
 */

export enum ChargerStatuses {
    Online = 'Online',
    Offline = 'Offline',
    NeverConnected = 'NeverConnected',
    Unknown = 'Unknown',
    Available = 'Available',
    Preparing = 'Preparing',
    Charging = 'Charging',
    SuspendedEvse = 'SuspendedEVSE',
    SuspendedEv = 'SuspendedEV',
    Finishing = 'Finishing',
    Reserved = 'Reserved',
    Unavailable = 'Unavailable',
    Faulted = 'Faulted'
}

/**
 * describes a full OCPP charging session starting from StartTransaction.req through StopTransaction.conf
 * @export
 * @interface ChargingSession
 */
export interface ChargingSession {
    /**
     * originates from `ocpp_logs.id`
     * @type {number}
     * @memberof ChargingSession
     */
    ocpp_log_id?: number;
    /**
     * originates from `ocpp_logs.charge_point_identity`
     * @type {string}
     * @memberof ChargingSession
     */
    charge_point_identity?: string;
    /**
     * the timestamp as described by §6.45, StartTransaction.req
     * @type {string}
     * @memberof ChargingSession
     */
    start_transaction_timestamp?: string;
    /**
     * the timestamp as described by §6.49, StopTransaction.req
     * @type {string}
     * @memberof ChargingSession
     */
    stop_transaction_timestamp?: string;
    /**
     * 
     * @type {ChargingSessionChargingDuration}
     * @memberof ChargingSession
     */
    charging_duration?: ChargingSessionChargingDuration;
    /**
     * in Wh
     * @type {number}
     * @memberof ChargingSession
     */
    meter_start?: number;
    /**
     * in Wh
     * @type {number}
     * @memberof ChargingSession
     */
    meter_stop?: number;
    /**
     * energy in kWH with 4 fixed decimal places and trailing zeroes
     * @type {string}
     * @memberof ChargingSession
     */
    energy_kwh?: string;
    /**
     * 
     * @type {StopTransactionReason}
     * @memberof ChargingSession
     */
    charging_end_reason?: StopTransactionReason;
    /**
     * originates from `vehicle_charge_requests.id`
     * @type {number}
     * @memberof ChargingSession
     */
    vcr_id?: number;
    /**
     * originates from `vehicle_charge_requests.host_price_cents`
     * @type {number}
     * @memberof ChargingSession
     */
    earnings_cents?: number;
    /**
     * originates from `chargers.id`
     * @type {number}
     * @memberof ChargingSession
     */
    charger_id?: number;
    /**
     * originates from `chargers.stickerId`
     * @type {string}
     * @memberof ChargingSession
     */
    charger_sticker_id?: string;
    /**
     * originates from `chargers.name`
     * @type {string}
     * @memberof ChargingSession
     */
    charger_name?: string;
    /**
     * originates from `coalesce(charger.\"stickerId\" , charger.name , charger.id::text)`
     * @type {string}
     * @memberof ChargingSession
     */
    charger_derived_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingSession
     */
    charger_timezone?: string;
}
/**
 * 
 * @export
 * @interface ChargingSessionChargingDuration
 */
export interface ChargingSessionChargingDuration {
    /**
     * 
     * @type {number}
     * @memberof ChargingSessionChargingDuration
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingSessionChargingDuration
     */
    minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingSessionChargingDuration
     */
    seconds?: number;
}
/**
 * 
 * @export
 * @interface ChargingSessionGrouped
 */
export interface ChargingSessionGrouped {
    /**
     * originates from `chargers.id`
     * @type {number}
     * @memberof ChargingSessionGrouped
     */
    charger_id?: number;
    /**
     * originates from `chargers.stickerId`
     * @type {string}
     * @memberof ChargingSessionGrouped
     */
    charger_sticker_id?: string;
    /**
     * originates from `chargers.name`
     * @type {string}
     * @memberof ChargingSessionGrouped
     */
    charger_name?: string;
    /**
     * 
     * @type {number}
     * @memberof ChargingSessionGrouped
     */
    session_count?: number;
    /**
     * 
     * @type {ChargingSessionChargingDuration}
     * @memberof ChargingSessionGrouped
     */
    charging_duration?: ChargingSessionChargingDuration;
    /**
     * energy in kWH with 4 fixed decimal places and trailing zeroes
     * @type {string}
     * @memberof ChargingSessionGrouped
     */
    energy_kwh?: string;
    /**
     * originates from `vehicle_charge_requests.host_price_cents`
     * @type {number}
     * @memberof ChargingSessionGrouped
     */
    earnings_cents?: number;
}
/**
 * 
 * @export
 * @interface RemoteStartOrStopTransactionResponse
 */
export interface RemoteStartOrStopTransactionResponse {
    /**
     * the sentMessage object confirms to OCPP 1.6 §6.33
     * @type {object}
     * @memberof RemoteStartOrStopTransactionResponse
     */
    sentMessage?: object;
    /**
     * 
     * @type {RemoteStartOrStopTransactionResponseAcknowledgment}
     * @memberof RemoteStartOrStopTransactionResponse
     */
    acknowledgment?: RemoteStartOrStopTransactionResponseAcknowledgment;
}
/**
 * 
 * @export
 * @interface RemoteStartOrStopTransactionResponseAcknowledgment
 */
export interface RemoteStartOrStopTransactionResponseAcknowledgment {
    /**
     * 
     * @type {RemoteStartOrStopTransactionResponseStatus}
     * @memberof RemoteStartOrStopTransactionResponseAcknowledgment
     */
    status?: RemoteStartOrStopTransactionResponseStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RemoteStartOrStopTransactionResponseStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

/**
 * See OCPP v1.6 §7.36
 * @export
 * @enum {string}
 */

export enum StopTransactionReason {
    DeAuthorized = 'DeAuthorized',
    EmergencyStop = 'EmergencyStop',
    EvDisconnected = 'EVDisconnected',
    HardReset = 'HardReset',
    Local = 'Local',
    Other = 'Other',
    PowerLoss = 'PowerLoss',
    Reboot = 'Reboot',
    Remote = 'Remote',
    SoftReset = 'SoftReset',
    UnlockCommand = 'UnlockCommand'
}


/**
 * AllChargePointsApi - axios parameter creator
 * @export
 */
export const AllChargePointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * returns a list of ChargePoints and their statuses
         * @summary returns a list of ChargePoints and their statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllChargers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/chargers/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieves individual charging sessions by charger_id
         * @summary retrieves individual charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllSessions: async (chargingStart: string, chargingEnd: string, chargerId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargingStart' is not null or undefined
            assertParamExists('listAllSessions', 'chargingStart', chargingStart)
            // verify required parameter 'chargingEnd' is not null or undefined
            assertParamExists('listAllSessions', 'chargingEnd', chargingEnd)
            const localVarPath = `/chargers/v1/charging-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chargerId !== undefined) {
                localVarQueryParameter['charger_id'] = chargerId;
            }

            if (chargingStart !== undefined) {
                localVarQueryParameter['charging_start'] = (chargingStart as any instanceof Date) ?
                    (chargingStart as any).toISOString() :
                    chargingStart;
            }

            if (chargingEnd !== undefined) {
                localVarQueryParameter['charging_end'] = (chargingEnd as any instanceof Date) ?
                    (chargingEnd as any).toISOString() :
                    chargingEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieves aggregated charging sessions by charger_id
         * @summary retrieves aggregated charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {string} [timeframe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllSessionsGrouped: async (chargingStart: string, chargingEnd: string, chargerId?: string, timeframe?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargingStart' is not null or undefined
            assertParamExists('listAllSessionsGrouped', 'chargingStart', chargingStart)
            // verify required parameter 'chargingEnd' is not null or undefined
            assertParamExists('listAllSessionsGrouped', 'chargingEnd', chargingEnd)
            const localVarPath = `/chargers/v1/charging-sessions-grouped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chargerId !== undefined) {
                localVarQueryParameter['charger_id'] = chargerId;
            }

            if (chargingStart !== undefined) {
                localVarQueryParameter['charging_start'] = (chargingStart as any instanceof Date) ?
                    (chargingStart as any).toISOString() :
                    chargingStart;
            }

            if (chargingEnd !== undefined) {
                localVarQueryParameter['charging_end'] = (chargingEnd as any instanceof Date) ?
                    (chargingEnd as any).toISOString() :
                    chargingEnd;
            }

            if (timeframe !== undefined) {
                localVarQueryParameter['timeframe'] = timeframe;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllChargePointsApi - functional programming interface
 * @export
 */
export const AllChargePointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllChargePointsApiAxiosParamCreator(configuration)
    return {
        /**
         * returns a list of ChargePoints and their statuses
         * @summary returns a list of ChargePoints and their statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllChargers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Charger>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllChargers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * retrieves individual charging sessions by charger_id
         * @summary retrieves individual charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllSessions(chargingStart: string, chargingEnd: string, chargerId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChargingSession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllSessions(chargingStart, chargingEnd, chargerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * retrieves aggregated charging sessions by charger_id
         * @summary retrieves aggregated charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {string} [timeframe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllSessionsGrouped(chargingStart: string, chargingEnd: string, chargerId?: string, timeframe?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChargingSessionGrouped>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllSessionsGrouped(chargingStart, chargingEnd, chargerId, timeframe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllChargePointsApi - factory interface
 * @export
 */
export const AllChargePointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllChargePointsApiFp(configuration)
    return {
        /**
         * returns a list of ChargePoints and their statuses
         * @summary returns a list of ChargePoints and their statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllChargers(options?: any): AxiosPromise<Array<Charger>> {
            return localVarFp.listAllChargers(options).then((request) => request(axios, basePath));
        },
        /**
         * retrieves individual charging sessions by charger_id
         * @summary retrieves individual charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllSessions(chargingStart: string, chargingEnd: string, chargerId?: string, options?: any): AxiosPromise<Array<ChargingSession>> {
            return localVarFp.listAllSessions(chargingStart, chargingEnd, chargerId, options).then((request) => request(axios, basePath));
        },
        /**
         * retrieves aggregated charging sessions by charger_id
         * @summary retrieves aggregated charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {string} [timeframe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllSessionsGrouped(chargingStart: string, chargingEnd: string, chargerId?: string, timeframe?: string, options?: any): AxiosPromise<Array<ChargingSessionGrouped>> {
            return localVarFp.listAllSessionsGrouped(chargingStart, chargingEnd, chargerId, timeframe, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllChargePointsApi - object-oriented interface
 * @export
 * @class AllChargePointsApi
 * @extends {BaseAPI}
 */
export class AllChargePointsApi extends BaseAPI {
    /**
     * returns a list of ChargePoints and their statuses
     * @summary returns a list of ChargePoints and their statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllChargePointsApi
     */
    public listAllChargers(options?: any) {
        return AllChargePointsApiFp(this.configuration).listAllChargers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieves individual charging sessions by charger_id
     * @summary retrieves individual charging sessions by charger_id
     * @param {string} chargingStart 
     * @param {string} chargingEnd 
     * @param {string} [chargerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllChargePointsApi
     */
    public listAllSessions(chargingStart: string, chargingEnd: string, chargerId?: string, options?: any) {
        return AllChargePointsApiFp(this.configuration).listAllSessions(chargingStart, chargingEnd, chargerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieves aggregated charging sessions by charger_id
     * @summary retrieves aggregated charging sessions by charger_id
     * @param {string} chargingStart 
     * @param {string} chargingEnd 
     * @param {string} [chargerId] 
     * @param {string} [timeframe] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllChargePointsApi
     */
    public listAllSessionsGrouped(chargingStart: string, chargingEnd: string, chargerId?: string, timeframe?: string, options?: any) {
        return AllChargePointsApiFp(this.configuration).listAllSessionsGrouped(chargingStart, chargingEnd, chargerId, timeframe, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpecificChargePointApi - axios parameter creator
 * @export
 */
export const SpecificChargePointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} chargePointIdentity 
         * @param {number} [vcrId] a valid &#x60;VCR.id&#x60; **OR** the value &#x60;-1&#x60; if this is a override where the &#x60;VCR.id&#x60; is unknown
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargersV1ChargePointIdentityRemoteStartTransactionPost: async (chargePointIdentity: string, vcrId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargePointIdentity' is not null or undefined
            assertParamExists('chargersV1ChargePointIdentityRemoteStartTransactionPost', 'chargePointIdentity', chargePointIdentity)
            const localVarPath = `/chargers/v1/{chargePointIdentity}/remote-start-transaction`
                .replace(`{${"chargePointIdentity"}}`, encodeURIComponent(String(chargePointIdentity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vcrId !== undefined) {
                localVarQueryParameter['vcrId'] = vcrId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chargePointIdentity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargersV1ChargePointIdentityRemoteStopTransactionPost: async (chargePointIdentity: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargePointIdentity' is not null or undefined
            assertParamExists('chargersV1ChargePointIdentityRemoteStopTransactionPost', 'chargePointIdentity', chargePointIdentity)
            const localVarPath = `/chargers/v1/{chargePointIdentity}/remote-stop-transaction`
                .replace(`{${"chargePointIdentity"}}`, encodeURIComponent(String(chargePointIdentity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecificChargePointApi - functional programming interface
 * @export
 */
export const SpecificChargePointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecificChargePointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} chargePointIdentity 
         * @param {number} [vcrId] a valid &#x60;VCR.id&#x60; **OR** the value &#x60;-1&#x60; if this is a override where the &#x60;VCR.id&#x60; is unknown
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chargersV1ChargePointIdentityRemoteStartTransactionPost(chargePointIdentity: string, vcrId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteStartOrStopTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chargersV1ChargePointIdentityRemoteStartTransactionPost(chargePointIdentity, vcrId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} chargePointIdentity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chargersV1ChargePointIdentityRemoteStopTransactionPost(chargePointIdentity: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteStartOrStopTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chargersV1ChargePointIdentityRemoteStopTransactionPost(chargePointIdentity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpecificChargePointApi - factory interface
 * @export
 */
export const SpecificChargePointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecificChargePointApiFp(configuration)
    return {
        /**
         * 
         * @param {string} chargePointIdentity 
         * @param {number} [vcrId] a valid &#x60;VCR.id&#x60; **OR** the value &#x60;-1&#x60; if this is a override where the &#x60;VCR.id&#x60; is unknown
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargersV1ChargePointIdentityRemoteStartTransactionPost(chargePointIdentity: string, vcrId?: number, options?: any): AxiosPromise<RemoteStartOrStopTransactionResponse> {
            return localVarFp.chargersV1ChargePointIdentityRemoteStartTransactionPost(chargePointIdentity, vcrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} chargePointIdentity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargersV1ChargePointIdentityRemoteStopTransactionPost(chargePointIdentity: string, options?: any): AxiosPromise<RemoteStartOrStopTransactionResponse> {
            return localVarFp.chargersV1ChargePointIdentityRemoteStopTransactionPost(chargePointIdentity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecificChargePointApi - object-oriented interface
 * @export
 * @class SpecificChargePointApi
 * @extends {BaseAPI}
 */
export class SpecificChargePointApi extends BaseAPI {
    /**
     * 
     * @param {string} chargePointIdentity 
     * @param {number} [vcrId] a valid &#x60;VCR.id&#x60; **OR** the value &#x60;-1&#x60; if this is a override where the &#x60;VCR.id&#x60; is unknown
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecificChargePointApi
     */
    public chargersV1ChargePointIdentityRemoteStartTransactionPost(chargePointIdentity: string, vcrId?: number, options?: any) {
        return SpecificChargePointApiFp(this.configuration).chargersV1ChargePointIdentityRemoteStartTransactionPost(chargePointIdentity, vcrId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} chargePointIdentity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecificChargePointApi
     */
    public chargersV1ChargePointIdentityRemoteStopTransactionPost(chargePointIdentity: string, options?: any) {
        return SpecificChargePointApiFp(this.configuration).chargersV1ChargePointIdentityRemoteStopTransactionPost(chargePointIdentity, options).then((request) => request(this.axios, this.basePath));
    }
}


