import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosPromise, CancelTokenSource } from "axios";

export enum ErrorCode {
  SERVER_ERROR = "SERVER_ERROR",
  TIMEOUT_ERROR = "TIMEOUT_ERROR",
  NETWORK_ERROR = "NETWORK_ERROR",
  REQUEST_ERROR = "REQUEST_ERROR",
  CANCEL_ERROR = "CANCEL_ERROR"
}

interface Refresher {
  requestRefresh<T = any>(url: string): AxiosPromise<T>;
}

const ocppAuthorizationCredentials = Buffer.from(`${process.env.REACT_APP_OCPP_API_USERNAME}:${process.env.REACT_APP_OCPP_API_PASSWORD}`).toString("base64");

const CONFIG: AxiosRequestConfig = {
  baseURL: "/",
  headers: {
    Accept: "application/json",
    "Csrf-Token": "nocheck",
    "Authorization": `Basic ${ocppAuthorizationCredentials}`
  },
};

const instance: AxiosInstance = axios.create(CONFIG);

instance.interceptors.response.use(undefined, (error: AxiosError) => {
  const response = error.response;
  
  if (response && response.data.error === "needslogin") {
    return window.location.assign(response.data.redirect);
  }

  error.code = getErrorCode(error);
  return Promise.reject(error);
});

const getErrorCode = (error: AxiosError): ErrorCode => {
  if (error.response) {
    return ErrorCode.SERVER_ERROR;
  } else if (error.request) {
    if (error.code === "ECONNABORTED") {
      return ErrorCode.TIMEOUT_ERROR;
    } else {
      return ErrorCode.NETWORK_ERROR;
    }
  } else if (axios.isCancel(error)) {
    return ErrorCode.CANCEL_ERROR;
  } else {
    return ErrorCode.REQUEST_ERROR;
  }
};

export default instance;

export const createRefresher = (): Refresher => {
  let cancelToken: CancelTokenSource | null;

  return {
    requestRefresh: url => {
      if (cancelToken) cancelToken.cancel("Canceled in-flight request.");

      cancelToken = axios.CancelToken.source();

      return instance.get(url, {
        cancelToken: cancelToken.token,
        headers: {
          "Refresh-Only": "refresh"
        },
      });
    },
  };
};

export const getDedupedRequest = (method: AxiosRequestConfig["method"]) => {
  let cancelToken: CancelTokenSource | null;
  return <T = any>(url: string, config: AxiosRequestConfig): AxiosPromise<T> => {
    if (cancelToken) cancelToken.cancel("Canceled in-flight request.");
    cancelToken = axios.CancelToken.source();
    return instance.get(url, { cancelToken: cancelToken.token, method, ...config });
  };
};