import { ChargingSessionGrouped, ChargingSessionChargingDuration } from '../../../api/ocpp/api';

export interface Data {
  charger_id?: number,
  charger_name?: string,
  charger_sticker_id?: string,
  session_count?: number,
  charging_duration?: ChargingSessionChargingDuration,
  energy_kwh?: string,
  earnings_cents?: number,
}

const formatNum = (num: number) => {
  if (num < 10) return `0${num}`;
  return num;
}

function createData({ charger_id, charger_name, charger_sticker_id, session_count, charging_duration, energy_kwh, earnings_cents }: Data) {
  return {
    charger_id,
    charger_name,
    charger_sticker_id,
    session_count,
    charging_duration: `${charging_duration?.hours ? formatNum(charging_duration.hours) : '00'}:${charging_duration?.minutes ? formatNum(charging_duration.minutes) : '00'}:${charging_duration?.seconds ? formatNum(charging_duration.seconds) : '00'}`,
    energy_kwh: parseFloat(Number(energy_kwh).toFixed(2)),
    earnings_cents: `$${(earnings_cents / 100).toFixed(2)}`,
  };
}

interface TableDataProps {
  chargers: ChargingSessionGrouped[];
}

export function TableData({ chargers = [] }: TableDataProps) {
  return chargers.map((c: ChargingSessionGrouped) => {
   

    return createData(c);
  });
}
