import { Colors } from "../../components/common/design-system";
import styled from "styled-components";
import { BREAKPOINTS } from "styles/breakpoints";

export const StyledNavigation = styled.nav`
  height: 1000px;
  width: 200px;
  padding: 42px 24px;

  @media (min-width: ${BREAKPOINTS.MEGA}) {
    padding: 24px;
    width: 15vw;
  }
`;

export const ButtonMenu = styled.button`
  height: 30px;
  border: none;
  background: none;
`;

export const NavigationLogo = styled.img`
  max-width: 250px;
  max-height: 30px;
  margin: 0 auto 12px;
`;

export const NavigationList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const NavigationListItem = styled.li`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 15px 0;
`;

export const Sidebar = styled.div<{ isOpen: boolean }>`
  width: 280px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => isOpen ? "0" : "-100%"};
  z-index: 1;
  transition: 0.5s;
  z-index: 999;
`;

export const SDHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const SidebarOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: ${({ isOpen }) => isOpen ? "1" : "0"};
  visibility: ${({ isOpen }) => isOpen ? "visible" : "hidden"};;
`;

export const SDBody = styled.div`
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;

  ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }

  ul li {
    list-style: none;
    margin-bottom: 8px;
  }

  ul li .sd-link {
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const NavigationListLink = styled.a<{ isActive: boolean }>`
  align-items: center;
  color: ${({ isActive }) => isActive ? Colors.orange.accent : Colors.black };
  display: flex;
  text-decoration: none;

  svg {
    margin-right: 12px;
    color: ${({ isActive }) => isActive ? Colors.orange.accent : Colors.black }
    fill: ${({ isActive }) => isActive ? Colors.orange.accent : Colors.black }
  }
`;

export const NavigationListLinkHeader = styled.p`
align-items: center;
  color: ${Colors.black};
  display: flex;
  text-decoration: none;
  cursor: pointer;
  margin: 0;

  svg {
    margin-right: 12px;
    color: ${Colors.black}
    fill: ${Colors.black}
  }
`

export const NavigationListSubLink = styled.a<{ isActive: boolean }>`
  align-items: center;
  color: ${({ isActive }) => isActive ? Colors.orange.accent : Colors.black };
  display: flex;
  text-decoration: none;
  font-size: 16px;
  margin-left: 36px;
  margin-bottom: 5px;

  svg {
    margin-right: 12px;
    color: ${({ isActive }) => isActive ? Colors.orange.accent : Colors.black }
    fill: ${({ isActive }) => isActive ? Colors.orange.accent : Colors.black }
  }
`