import * as React from 'react';
import { BaseText } from './styles';
import { BaseTextProps } from './constants';

type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

interface Props extends BaseTextProps {
  size?: Size;
}

function getSubheadStyles(size: Size): BaseTextProps {
  switch (size) {
    case 'xl': {
      return {
        fontSize: '26px',
        lineHeight: '28px',
      };
    }
    case 'lg': {
      return {
        fontSize: '18px',
        lineHeight: '24px',
      };
    }
    case 'md': {
      return {
        fontSize: '16px',
        lineHeight: '20px',
      };
    }
    case 'sm': {
      return {
        fontSize: '14px',
        lineHeight: '16px',
      };
    }
    case 'xs': {
      return {
        fontSize: '12px',
        lineHeight: '16px',
      };
    }
    default: {
      throw new Error(`Unknown Text.Subhead size prop: ${size}`);
    }
  }
}

const SubheadingText = React.forwardRef<HTMLParagraphElement, Props>(
  ({ size = 'lg', as = 'p', ...restProps }: Props, ref) => (
    <BaseText bold ref={ref} as={as} {...getSubheadStyles(size)} {...restProps} />
  )
);

export default SubheadingText;
