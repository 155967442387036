import Switch from "@mui/material/Switch";

import { alpha, styled } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: orange[800],
    "&:hover": {
      backgroundColor: alpha(orange[800], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: orange[800],
  },
}));

export default OrangeSwitch;
