import React from 'react';
import styled from 'styled-components';
export interface SkeletonLoaderProps {
  className?: string;
  width?: string;
  rounded?: 'md' | 'lg';
  height?: string;
  style?: object;
}

const StyledBar = styled.div<{ width?: string, height?: string }>`
  @keyframes pulse {
    0% {
      background-position: 0% 0%
    }
    100% {
      background-position: -135% 0%
    }
  }

  width: ${({ width }) => width ? width : '100%'};
  height: ${({ height }) => height ? height : '30px'};
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
`

const SkeletonLoader = ({ width, height, style }: SkeletonLoaderProps) => {
  return (
    <StyledBar style={style} width={width} height={height} />
  );
};

export { SkeletonLoader };
