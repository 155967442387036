import React from 'react';
import Cookies from 'js-cookie';
import { Route } from 'react-router-dom';

interface Props {
  component: React.FunctionComponent | string;
  roles: string[];
  path?: string;
  exact?: boolean;
}

const PrivateRoute = ({ component: Component, roles, ...rest }: Props) => {
  const accessToken = Cookies.get('authKey');   
  const role = Cookies.get('role');
  const env = process.env.REACT_APP_BUILD_VERSION;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessToken && role && roles.includes(role)) {
          return React.createElement(Component);
        } else {
          // you'll need to have EVmatch web running if local
          window.location.replace(
            `${
              env === "local"
                ? "http://localhost:8080"
                : `https://${
                    env === "staging" ? "app-staging." : "app."
                  }evmatch.com`
            }/login?url=dashboard`
          );
          return null;
        }
      }}
    />
  );
};

export default PrivateRoute;
