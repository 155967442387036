import Cookies from 'js-cookie';

import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Charger } from '../../api/ocpp/api';
import { Data } from './TableView/TableData';
import { allChargePointsApi, allOcpiChargePointsApi } from '../../api/client';
import Select from "react-select";

import Header from '../../components/Header';
import TableView from './TableView';
import { Text } from "../../components/common/design-system";
import { Box, FormControl, Select as MuiSelect, Tooltip, TextField, Button, InputLabel, MenuItem } from "@mui/material";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { subMonths, getYear, subWeeks, subYears, startOfYear } from 'date-fns';
import { InfoOutlined } from '@mui/icons-material';
interface OptionType {
  label: string;
  value: string;
}

interface SessionDataProps {
  time?: string;
  chargerId?: string;
  start?: string;
  end?: string;
}
interface GroupType {
  label: string | React.ReactNode;
  options: OptionType[];
}

const Reports = () => {
  const accessToken = Cookies.get('authKey');  
  const [startDate, setStartDate] = useState<string>(subMonths(new Date(), 1).toISOString());
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const [activeChargers, setActiveChargers] = useState<{label: string, value: string}[]>();
  const [timeframe, setTimeframe] = useState("week");
  const [chargers, setChargers] = useState<Charger[]>([]);
  const [allChargers, setAllChargers] = useState<Charger[]>([]);
  const [uniqueAddresses, setUniqueAddresses] = useState([])
  const [groupedChargers, setGroupedChargers] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const params: any = useParams();
  const { id } = params;

  useEffect(() => {
    switch (timeframe) {
      case "week":
        setShowRange(false);
        const weekAgo = subWeeks(new Date(), 1);
        setStartDate(weekAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "month":
        setShowRange(false);
        const monthAgo = subMonths(new Date(), 1);
        setStartDate(monthAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "year":
        setShowRange(false);
        const yearAgo = subYears(new Date(), 1);
        setStartDate(yearAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "ytd":
        setShowRange(false);
        const ytd = startOfYear(new Date()).toISOString();
        setStartDate(ytd);
        setEndDate(new Date().toISOString());
        return;
      case "all":
        setShowRange(false);
        setStartDate(new Date("01/01/2022").toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "custom":
        setShowRange(true);
        return;
      default:
        const weekAgo2 = subWeeks(new Date(), 1);
        setStartDate(weekAgo2.toISOString());
        setEndDate(new Date().toISOString());
    }
  }, [timeframe]);

  const fetchChargerData = async () => {
    const combinedChargers = [];
    setLoading(true);
    try {
      const accessToken = Cookies.get('authKey');  
      const { data: chargerData } = await allChargePointsApi.listAllChargers({ 
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      if (chargerData.length) combinedChargers.push(...chargerData);
      const { data: ocpiChargers } = await allOcpiChargePointsApi.listAllEvseChargers({ 
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      if (ocpiChargers.length) combinedChargers.push(...ocpiChargers);
      
      const groupedData = combinedChargers.reduce((groups, item) => {
        const addressLine = `${item.address_line1}, ${item.city}, ${item.state} ${item.zip}`;
        groups[addressLine] = groups[addressLine] || [];
        groups[addressLine].push(item);
        return groups;
      }, {});
      setLoading(false);
      setAllChargers(combinedChargers); 
      setUniqueAddresses(Object.keys(groupedData)); 
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  };

  const fetchSessionData = async ({ time, chargerId, start = startDate, end = endDate }: SessionDataProps) => {
    setLoading(true);
    try {
      const accessToken = Cookies.get('authKey');   
      const combinedSessionData = [];
      console.log(start, end, chargerId, time);

      const { data } = await allChargePointsApi.listAllSessionsGrouped(
        start,
        end,
        (chargerId || `${activeChargers.map(x => x.value).join(",")}`),
        (time || timeframe),
        {
          withCredentials: true,
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${accessToken}`,
          }
        }
      );
      combinedSessionData.push(...data);
      const { data: ocpiSessions } = await allOcpiChargePointsApi.listAllSessions(
        start,
        end,
        (chargerId || `${activeChargers.map(x => x.value).join(",")}`),
        (time || timeframe),
        {
          withCredentials: true,
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${accessToken}`,
          }
        }
      );
      combinedSessionData.push(...ocpiSessions);

      const combinedObject = (data) => data.reduce((accumulator, currentValue, index) => {
        if (index === 0) {
          // Assign constant properties from the first element
          accumulator.charger_id = currentValue.charger_id;
          accumulator.charger_name = currentValue.charger_name;
          accumulator.charger_sticker_id = currentValue.charger_sticker_id;
        }
        // Initialize session count, energy kWh, and earnings cents
        accumulator.session_count = (accumulator.session_count || 1) + (currentValue.session_count || 1);
        accumulator.energy_kwh = (parseFloat(accumulator.energy_kwh) || 0) + parseFloat(currentValue.energy_kwh || '0') + '';
        accumulator.earnings_cents = (accumulator.earnings_cents || 0) + (currentValue.earnings_cents || 0);

        // Initialize charging duration
        accumulator.charging_duration = accumulator.charging_duration || { hours: 0, minutes: 0, seconds: 0 };

        // Aggregate charging duration
        const chargingDuration = currentValue.charging_duration || { hours: 0, minutes: 0, seconds: 0 };
        accumulator.charging_duration.hours += chargingDuration.hours || 0;
        accumulator.charging_duration.minutes += chargingDuration.minutes || 0;
        accumulator.charging_duration.seconds += chargingDuration.seconds || 0;

        // Normalize charging duration
        while (accumulator.charging_duration.seconds >= 60) {
            accumulator.charging_duration.minutes += Math.floor(accumulator.charging_duration.seconds / 60);
            accumulator.charging_duration.seconds %= 60;
        }

        while (accumulator.charging_duration.minutes >= 60) {
            accumulator.charging_duration.hours += Math.floor(accumulator.charging_duration.minutes / 60);
            accumulator.charging_duration.minutes %= 60;
        }

      return accumulator;
      }, {});

      const cleanData = combinedSessionData
        .filter(arr => {
          if (Array.isArray(arr)) {
            return arr.length > 0;
          }
        })
        .reduce((acc, arr) => {
            if (Array.isArray(arr)) {
              arr.forEach(session => {
                const { charger_id, energy_kwh, earnings_cents, session_count, charging_duration } = session;
                if (!acc[charger_id]) {
                    acc[charger_id] = {
                        charger_id: charger_id,
                        charger_sticker_id: session.charger_sticker_id,
                        charger_name: session.charger_name,
                        session_count: 0,
                        energy_kwh: 0,
                        earnings_cents: 0,
                        charging_duration: { minutes: 0, seconds: 0, hours: 0 }
                    };
                }
                // Aggregating data
                acc[charger_id].session_count += session_count || 1;
                acc[charger_id].energy_kwh += parseFloat(energy_kwh);
                acc[charger_id].earnings_cents += parseFloat(earnings_cents);

                // Aggregating charging duration
                const duration = acc[charger_id].charging_duration;
                duration.minutes += charging_duration.minutes || 0;
                duration.seconds += charging_duration.seconds || 0;
                duration.hours += charging_duration.hours || 0;

                // Normalize time (convert seconds to minutes)
                if (duration.seconds >= 60) {
                  duration.minutes += Math.floor(duration.seconds / 60);
                  duration.seconds %= 60;
                }

                if (duration.minutes >= 60) {
                  duration.hours += Math.floor(duration.minutes / 60);
                  duration.minutes %= 60;
              }
            });
            return acc;
            }
        }, {});
     // console.log(cleanData);
      setGroupedChargers(Array.isArray(combinedSessionData[0]) ? Object.values(cleanData) : [combinedObject(combinedSessionData)]); 
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  };

  const handleFilterChange = (name: string, value: OptionType[] | any) => {
    switch (name) {
      case "timeframe":
        setTimeframe(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "activeCharger":
        setActiveChargers(value);
    }
  };

  const handleFetchSessions = () => {
    fetchSessionData({
      time: "year",
      chargerId: activeChargers?.length ? `${activeChargers.map(x => x.value).join(",")}` : null,
      start: startDate,
      end: endDate,
    });
  };

  useEffect(() => {
    if (!chargers.length) {
      fetchChargerData();
    }
  }, [chargers.length]);

  const createGroup = (
    groupName: string,
    options: OptionType[],
    setValue
  ): GroupType => {
    return {
      label: (
        <div
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            color: "black",
          }}
          onClick={() => {
            setValue("activeCharger", options)
          }
            
          }
        >
          {groupName}
        </div>
      ),
      options: options,
    };
  };

  const options: GroupType[] = uniqueAddresses.map(address => {
    const chargersPerAddress = allChargers.filter(x => `${x.address_line1}, ${x.city}, ${x.state} ${x.zip}` === address);
    const menuItems: OptionType[] = chargersPerAddress.map(charger => ({ label: charger.chargePointIdentity ? `${charger.chargePointIdentity}` : `${(charger.stickerId || charger.name || 'Unknown')}-${charger.chargerId} ${charger.stickerId ? `(${charger.stickerId})` : ''} ${!charger.active ? '(inactive)' : ''}`, value: String(charger.chargerId) }));
    const option = createGroup(address, menuItems, handleFilterChange);
    return option;
  });

  return (
    <div style={{ width: "100%" }}>
      <Header title="Reports - Stations" />

      <div style={{ marginBottom: 10, alignItems: "flex-end", display: "flex", marginRight: 5, marginLeft: 16 }}>
        <div style={{display: 'flex', marginLeft: 16, marginTop: 16, flexFlow: 'column', width: '50%', maxWidth: 650}}>
          <div>
            <Tooltip title="The number in parentheses is the sticker ID displayed on front of charger.">
              <div style={{ display: 'inline-flex', marginRight: 15}}>
                <Text>Chargers: </Text>
                <InfoOutlined />
              </div>
            </Tooltip>
          </div>
          <FormControl>
            <Select
              isDisabled={loading}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  background: 'transparent',
                  padding: 3,
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  color: 'black',
                  fontWeight: 400
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  minWidth: null,
                }),
                groupHeading: (baseStyles) => ({
                  ...baseStyles,
                  padding: '8px',
                  "&:hover": {
                    background: "#ddebff",
                  }
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  flexFlow: 'row',
                  overflow: 'auto',
                })
              }}
              onChange={(option) => {
                handleFilterChange("activeCharger", option)
              }}
              closeMenuOnSelect={false}
              isMulti
              options={options as any}
              value={activeChargers}
              placeholder="Select"
            />
          </FormControl>
        </div>
        <Box sx={{ minWidth: 120, ml: 2 }}>
          <FormControl fullWidth style={{height: 44}}>
            <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
            <MuiSelect style={{height: 44}} disabled={loading} size='small' labelId="demo-simple-select-label" label="Breakdown" displayEmpty value={timeframe} onChange={(e) => handleFilterChange("timeframe", e.target.value as string)}>
              <MenuItem value="week">Last 7 Days</MenuItem>
              <MenuItem value="month">Last 30 Days</MenuItem>
              <MenuItem value="ytd">Year-to-date</MenuItem>
              <MenuItem value="all">All time</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </MuiSelect>
          </FormControl>  
        </Box>
        {showRange && (
          <Box>
            <FormControl sx={{ mt: 3, ml:1,  width: 150, p: 0 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  disabled={loading}
                  onChange={(newValue) => {
                    const date = new Date(newValue).toISOString();
                    handleFilterChange("startDate", date);
                  }}
                  renderInput={(params) => <TextField style={{height: 27}} size="small" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ mt: 3, ml:1,  width: 150, p: 0, height: 44}}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  disabled={loading}
                  onChange={(newValue) => {
                    const date = new Date(newValue).toISOString();
                    handleFilterChange("endDate", date);
                  }}
                  renderInput={(params) => <TextField style={{height: 27}} inputProps={{style: { height: 27 }}} size="small" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        )}
        <Button disabled={loading || !activeChargers?.length} onClick={handleFetchSessions} style={{ marginBottom: 5, marginLeft: 12}}>Submit</Button>
      </div>

      <TableView chargers={groupedChargers} loading={loading} />
    </div>
  );
};

export default Reports;
