import * as React from 'react';
import { Spacing } from '../primitives/spacing';
import { BaseTextProps } from './constants';
import { BaseText } from './styles';

export type HeadingSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';

type CustomizableHeadingProps = Omit<BaseTextProps, 'fontSize' | 'bold' | 'italic' | 'underline'>;

interface HeadingProps extends CustomizableHeadingProps {
  size?: HeadingSize;
}

function getHeaderStyles(size: HeadingSize): BaseTextProps {
  switch (size) {
    case 'xl': {
      return {
        fontSize: '80px',
        lineHeight: '80px',
      };
    }
    case 'lg': {
      return {
        fontSize: '60px',
        lineHeight: '60px',
      };
    }
    case 'md': {
      return {
        fontSize: '48px',
        lineHeight: '40px',
      };
    }
    case 'sm': {
      return {
        fontSize: '40px',
        lineHeight: '36px',
      };
    }
    case 'xs': {
      return {
        fontSize: '32px',
        lineHeight: Spacing.scale[4],
      };
    }
    case 'xxs': {
      return {
        fontSize: '20px',
        lineHeight: '20px',
      };
    }
    default: {
      throw new Error(`Unknown Text.Heading size prop: ${size}`);
    }
  }
}

const getDefaultAs = (size: HeadingSize) => {
  switch (size) {
    case 'xl': {
      return 'h1';
    }
    case 'lg': {
      return 'h2';
    }
    case 'md': {
      return 'h3';
    }
    case 'sm': {
      return 'h4';
    }
    case 'xs': {
      return 'h5';
    }
    case 'xxs': {
      return 'h6';
    }
  }
};

const HeadingText = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ as, size = 'lg', ...restProps }: HeadingProps, ref) => {
    const defaultAs = getDefaultAs(size);
    return (
      <BaseText
        letterSpacing="-0.05em"
        ref={ref}
        as={as || defaultAs}
        bold
        {...getHeaderStyles(size)}
        {...restProps}
      />
    );
  }
);

export default HeadingText;
