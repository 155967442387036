export const Colors = {
  black: '#0d0d0d',
  white: '#ffffff',
  offwhite: '#e5e5e5',
  orange: {
    accent: '#E9722E',
  },
  blue: '#2C88BE',
  text: {
    heading: '#183B56',
    primary: '#1E2021',
    lightGrey1: '#646C73', 
  },
  background: {
    default: '#FCFCFD',
    tableForm: '#F4F4F4',
    white: '#ffffff',
    formLine: '#D5D5D6',
    line: '#E9E9E9',
  },
  states: {
    cancel: '#C32828',
    approved: '#427619',
    paid: '#A4961B', 
  },
  red: {
    warning: '#df5140',
  },
  gray: {
    default: '#E2E2E2',
    light: '#F5F5F5',
  },
} as const;
