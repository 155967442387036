import Cookies from 'js-cookie';

import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Charger } from '../../api/ocpp/api';
import { Charger as OcpiCharger } from '../../api/ocpi/api';
import { Data, getTimezoneAbbreviation } from './TableView/TableData';
import { allChargePointsApi, allOcpiChargePointsApi } from '../../api/client';
import { subMonths, subWeeks, subYears, startOfYear } from 'date-fns';

import Header from '../../components/Header';
import TableView from './TableView';
import { Box, FormControl, InputLabel, Tooltip, Select as MuiSelect, MenuItem, TextField, Button, } from '@mui/material';
import { Text } from '../../components/common/design-system';
import { Download, InfoOutlined } from '@mui/icons-material';
import Select from "react-select";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { format, formatInTimeZone } from 'date-fns-tz';


interface OptionType {
  label: string;
  value: string;
}

interface GroupType {
  label: string | React.ReactNode;
  options: OptionType[];
}

const Reports = () => {
  const accessToken = Cookies.get('authKey');  
  const [showRange, setShowRange] = useState(false);
  const [startDate, setStartDate] = useState<string>(subMonths(new Date(), 1).toISOString());
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const [allChargers, setAllChargers] = useState<Charger[]>([]);
  const [sessions, setSessions] = useState<Data[]>([]);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeframe, setTimeframe] = useState("week");
  const [uniqueAddresses, setUniqueAddresses] = useState<string[]>([]);

  const [activeChargers, setActiveChargers] = useState<{label: string, value: string}[]>();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const chargerIds = searchParams.get("chargerIds");
  const formatDateTime = (dtStr, part) => {
    const dt = new Date(dtStr);
    if (part === 'date') {
        return dt.toISOString().split('T')[0];
    } else if (part === 'time') {
        return dt.toISOString().split('T')[1].split('.')[0];
    }
  };

  const formatDuration = (duration) => {
    return `${duration.hours ? String(duration.hours).padStart(2, '0') : '00'}:${duration.minutes ? String(duration.minutes).padStart(2, '0') : '00'}:${duration.seconds ? String(duration.seconds).padStart(2, '0') : '00'}`;
  };

  const formatRevenue = (cents) => {
    return `$${(parseFloat(cents) / 100).toFixed(2)}`;
  };

  const formatData = (header, data) => {
    const csvRows = [header];
    data.forEach(record => {
      const row = [
          record.charger_sticker_id || record.charger_name || record.stickerId || record.name || record.charger_id,
          format(new Date(record.start_transaction_timestamp), 'MM/dd/yyyy'),
          `${formatInTimeZone(new Date(record.start_transaction_timestamp), record.charger_timezone, 'hh:mm a')} ${getTimezoneAbbreviation(record.charger_timezone)}`,
          format(new Date(record.stop_transaction_timestamp), 'MM/dd/yyyy'),
          `${formatInTimeZone(new Date(record.stop_transaction_timestamp), record.charger_timezone, 'hh:mm a')} ${getTimezoneAbbreviation(record.charger_timezone)}`,
          formatDuration(record.charging_duration),
          record.energy_kwh,
          formatRevenue(record.earnings_cents)
      ];
      csvRows.push(row.join(','));
    });

    return csvRows.join('\n');
  };

  const filterSearch = async({ start, end, chargerId, download = false }: { start: string, end: string, chargerId: string, download?: boolean}) => {
    setLoading(true);
    try {
      const combinedSessionData = [];
      const downloadSessions = [];
      const { data } = await allChargePointsApi.listAllSessions(
        start,
        end,
        chargerId,
        {
          withCredentials: true,
          headers: {
            "accept": download ? "text/csv" : "application/json",
            "Authorization": `Bearer ${accessToken}`,
          }
        }
      );
      if (data.length) {
        combinedSessionData.push(...data);
        downloadSessions.push(data);
      };

      const { data: ocpiSessions } = await allOcpiChargePointsApi.listAllSessions(
        start,
        end,
        chargerId,
        '',
        {
          withCredentials: true,
          headers: {
            "accept": download ? "text/csv" : "application/json",
            "Authorization": `Bearer ${accessToken}`,
          }
        }
      );
      const isEmpty = ocpiSessions.every(x => Array.isArray(x) && x.length);
      if (ocpiSessions && !isEmpty) {
        combinedSessionData.push(...ocpiSessions);
        downloadSessions.push(ocpiSessions);
      };
      if (download) {
        let stringData;
        if (downloadSessions.length > 1 && Array.isArray(downloadSessions[1])) {
          const header = downloadSessions[0];
          const nestedArray = downloadSessions[1].flat();
          stringData = formatData(header, nestedArray);
        } else {
          stringData = downloadSessions[0];
        }
        const a = document.createElement('a');
        a.download = `sessions-${formatDateTime(start, 'date')}.csv`;
        a.href = `data:text/csv;charset=utf-8,${encodeURI(stringData)}`;
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
        setLoading(false);
        return;
      }
      setSessions(combinedSessionData.flat());
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chargerIds && !sessions.length && !initialLoaded && allChargers?.length) {
      const chargers = chargerIds.split(',');
      setInitialLoaded(true);
      const selectedChargers = allChargers.filter(x => chargers.includes(String(x.chargerId))).map(charger => {
        return { label: charger.chargePointIdentity ? `${charger.chargePointIdentity}` : `${(charger.stickerId || charger.name || 'Unknown')}-${charger.chargerId} ${charger.stickerId ? `(${charger.stickerId})` : ''} ${!charger.active ? '(inactive)' : ''}`, value: String(charger.chargerId) }
      });
      setActiveChargers(selectedChargers);
    }
  }, [chargerIds, allChargers, initialLoaded]);

  const fetchChargerData = useCallback(async () => {
    const combinedChargers = [];
    setLoading(true);
    try {
      const accessToken = Cookies.get('authKey');  
      const { data: chargerData } = await allChargePointsApi.listAllChargers({ 
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      if (chargerData.length) combinedChargers.push(...chargerData);
      const { data: ocpiChargers } = await allOcpiChargePointsApi.listAllEvseChargers({ 
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      if (ocpiChargers.length) combinedChargers.push(...ocpiChargers);
     
      const groupedData = combinedChargers.reduce((groups, item) => {
        const addressLine = `${item.address_line1}, ${item.city}, ${item.state} ${item.zip}`;
        groups[addressLine] = groups[addressLine] || [];
        groups[addressLine].push(item);
        return groups;
      }, {});
      setAllChargers(combinedChargers); 
      setUniqueAddresses(Object.keys(groupedData)); 
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchChargerData();
  }, []);

  useEffect(() => {
    switch (timeframe) {
      case "week":
        setShowRange(false);
        const weekAgo = subWeeks(new Date(), 1);
        setStartDate(weekAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "month":
        setShowRange(false);
        const monthAgo = subMonths(new Date(), 1);
        setStartDate(monthAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "year":
        setShowRange(false);
        const yearAgo = subYears(new Date(), 1);
        setStartDate(yearAgo.toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "ytd":
        setShowRange(false);
        const ytd = startOfYear(new Date()).toISOString();
        setStartDate(ytd);
        setEndDate(new Date().toISOString());
        return;
      case "all":
        setShowRange(false);
        setStartDate(new Date("01/01/2022").toISOString());
        setEndDate(new Date().toISOString());
        return;
      case "custom":
        setShowRange(true);
        return;
      default:
        const weekAgo2 = subWeeks(new Date(), 1);
        setStartDate(weekAgo2.toISOString());
        setEndDate(new Date().toISOString());
    }
  }, [timeframe]);

  const handleFilterChange = (name: string, value: OptionType[] | any) => {
    switch (name) {
      case "timeframe":
        setTimeframe(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "activeCharger":
        setActiveChargers(value);
    }
  };

  const handleFetchSessions = () => {
    filterSearch({
      start: startDate,
      end: endDate,
      chargerId: activeChargers?.length ? `${activeChargers.map(x => x.value).join(",")}` : null,
    });
  };

  const createGroup = (
    groupName: string,
    options: OptionType[],
    setValue
  ): GroupType => {
    return {
      label: (
        <div
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            color: "black",
          }}
          onClick={() => {
            setValue("activeCharger", options)
          }
            
          }
        >
          {groupName}
        </div>
      ),
      options: options,
    };
  };

  const options: GroupType[] = uniqueAddresses.map(address => {
    const chargersPerAddress = allChargers.filter(x => `${x.address_line1}, ${x.city}, ${x.state} ${x.zip}` === address);
    const menuItems: OptionType[] = chargersPerAddress.map(charger => ({ label: charger.chargePointIdentity ? `${charger.chargePointIdentity}` : `${(charger.stickerId || charger.name || 'Unknown')}-${charger.chargerId} ${charger.stickerId ? `(${charger.stickerId})` : ''} ${!charger.active ? '(inactive)' : ''}`, value: String(charger.chargerId) }));
    const option = createGroup(address, menuItems, handleFilterChange);
    return option;
  });

  const handleShowResults = async(isDownload?: boolean) => {
    await filterSearch({ start: startDate, end: endDate, chargerId: activeChargers.map(x => x.value).join(','), download: isDownload });
  };

  return (
    <div>
      <Header title="Reports - Sessions" />

      <div style={{position: 'absolute', right: 54, top: 72}}>

      <Tooltip title="Download Session Data">
        <LoadingButton
          onClick={() => handleShowResults(true)}
          classes={{ root: 'Filters__Button' }}
          variant="contained"
          size="small"
          disabled={loading}
        >
          <Download />
        </LoadingButton>
      </Tooltip>
      </div>

      <div style={{ marginBottom: 10, alignItems: "flex-end", display: "flex", marginRight: 5, marginLeft: 16 }}>
        <div style={{display: 'flex', marginLeft: 16, marginTop: 16, flexFlow: 'column', width: '50%', maxWidth: 650}}>
          <div>
            <Tooltip title="The number in parentheses is the sticker ID displayed on front of charger.">
              <div style={{ display: 'inline-flex', marginRight: 15}}>
                <Text>Chargers: </Text>
                <InfoOutlined />
              </div>
            </Tooltip>
          </div>
          <FormControl>
            <Select
              isDisabled={loading}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  background: 'transparent',
                  padding: 3,
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  color: 'black',
                  fontWeight: 400
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  minWidth: null,
                }),
                groupHeading: (baseStyles) => ({
                  ...baseStyles,
                  padding: '8px',
                  "&:hover": {
                    background: "#ddebff",
                  }
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  flexFlow: 'row',
                  overflow: 'auto',
                })
              }}
              onChange={(option) => {
                handleFilterChange("activeCharger", option)
              }}
              closeMenuOnSelect={false}
              isMulti
              options={options as any}
              value={activeChargers}
              placeholder="Select"
            />
          </FormControl>
        </div>
        <Box sx={{ minWidth: 120, ml: 2 }}>
          <FormControl fullWidth style={{height: 44}}>
            <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
            <MuiSelect style={{height: 44}} disabled={loading} size='small' labelId="demo-simple-select-label" label="Breakdown" displayEmpty value={timeframe} onChange={(e) => handleFilterChange("timeframe", e.target.value as string)}>
              <MenuItem value="week">Last 7 Days</MenuItem>
              <MenuItem value="month">Last 30 Days</MenuItem>
              <MenuItem value="ytd">Year-to-date</MenuItem>
              <MenuItem value="all">All time</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </MuiSelect>
          </FormControl>  
        </Box>
        {showRange && (
          <Box>
            <FormControl sx={{ mt: 3, ml:1,  width: 150, p: 0 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  disabled={loading}
                  onChange={(newValue) => {
                    const date = new Date(newValue).toISOString();
                    handleFilterChange("startDate", date);
                  }}
                  renderInput={(params) => <TextField style={{height: 27}} size="small" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ mt: 3, ml:1,  width: 150, p: 0, height: 44}}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  disabled={loading}
                  onChange={(newValue) => {
                    const date = new Date(newValue).toISOString();
                    handleFilterChange("endDate", date);
                  }}
                  renderInput={(params) => <TextField style={{height: 27}} inputProps={{style: { height: 27 }}} size="small" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        )}
        <Button disabled={loading || !activeChargers?.length} onClick={handleFetchSessions} style={{ marginBottom: 5, marginLeft: 12}}>Submit</Button>
      </div>

      <TableView sessions={sessions} loading={loading}/>
    </div>
  );
};

export default Reports;
