import { Spacing } from '../spacing';

export type SpacerOption = keyof typeof Spacing.scale | keyof typeof Spacing.component;

export const SpacerShape = {
  m: 'margin',
  mt: 'margin-top',
  mr: 'margin-right',
  mb: 'margin-bottom',
  ml: 'margin-left',
  p: 'padding',
  pt: 'padding-top',
  pr: 'padding-right',
  pb: 'padding-bottom',
  pl: 'padding-left',
};
