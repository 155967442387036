import { useState, useEffect } from 'react';
import logo from '../../assets/logo.png';

import { ReactComponent as Pin } from '../../assets/icons/pin.svg';
import { ReactComponent as ClipBoard } from '../../assets/icons/clipboard.svg';
import { ReactComponent as Overview } from '../../assets/icons/overview.svg';
import { ReactComponent as Reports } from '../../assets/icons/reports.svg';
import { ReactComponent as Lightning } from '../../assets/icons/lightning.svg';
import { StyledNavigation, ButtonMenu, NavigationLogo, NavigationList, NavigationListItem, Sidebar, SDHeader, SidebarOverlay, SDBody, NavigationListLink, NavigationListLinkHeader, NavigationListSubLink} from "./styles";
import { MAP, LISTINGS, CHARGES, REPORTS, CHARGERS } from '../../constants/routeConstants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
const allRoutes = [
  { route: CHARGERS, text: 'Chargers', icon: <Lightning /> },
  { route: LISTINGS, text: 'Listings', icon: <ClipBoard /> },
  { route: CHARGES, text: 'Reservations', icon: <Lightning /> },
  { route: MAP, text: 'Map', icon: <Pin /> },
];

const isActive = (route) => {
  const paths = window.location.pathname.split('/');
  return  paths[paths.length - 1] === route.split('/')[1];
};
const LinkListItem = ({ route, text, icon }) => {
  return <NavigationListItem>
    <NavigationListLink
      isActive={isActive(route)}
      href={route}
    >
      {icon}
      {text}
    </NavigationListLink>
  </NavigationListItem>
};

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hideNav, setHideNav] = useState(false)
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
  }, [window.innerWidth])

  const resize = () => {
    if (window.innerWidth <= 760) setHideNav(true)
  }

  const ToggleSidebar = () => {
    isOpen === true ? setIsOpen(false) : setIsOpen(true);
  }
  return (
    hideNav ? <>
      <ButtonMenu icon="menu" onClick={ToggleSidebar} />
      <Sidebar isOpen={isOpen}>
        <SDHeader>
          <div className="btn btn-primary" onClick={ToggleSidebar}>
            <i className="fa fa-times"></i>
          </div>
          <SDBody>
            <NavigationLogo src={logo} alt="EVmatch Logo" />
            <ul>
              <NavigationList>
                {allRoutes.map(({ route, text, icon }) => (
                  <LinkListItem key={text} route={route} text={text} icon={icon} />
                ))}
              </NavigationList>
            </ul>
          </SDBody>
        </SDHeader>
      </Sidebar>
      <SidebarOverlay isOpen={isOpen} onClick={ToggleSidebar} />
    </>
      :
      <StyledNavigation>
        <NavigationLogo src={logo} alt="EVmatch Logo" />

        <NavigationList>
          <LinkListItem route="/" text="Overview" icon={<Overview />} />
          <NavigationListItem>
            <NavigationListLinkHeader onClick={() => setExpanded(!expanded)}>
              <Reports />
              Reports {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </NavigationListLinkHeader>
            <div style={{ maxHeight: expanded ? '100%' : 0, overflow: 'hidden', marginTop: expanded ? 10 : 0}}>
              <NavigationListSubLink isActive={isActive('/reports')} href="/reports">
                Stations
              </NavigationListSubLink>
              <NavigationListSubLink isActive={isActive('/sessions')} href="/reports/sessions">
                Sessions
              </NavigationListSubLink>
            </div>
          </NavigationListItem>
          {allRoutes.map(({ route, text, icon }) => (
            <LinkListItem key={text} route={route} text={text} icon={icon} />
          ))}
        </NavigationList>
        {/* <UserProfile /> */}
      </StyledNavigation>
  )
};

export default Navigation;
