
import Header from "../../components/Header";
// import ChartEnergySupplied from "./charts/EnergySupplied";
// import ChartChargingTime from "./charts/ChargingTime";
// import ChartEarnings from "./charts/Earnings";
import TableView from "./TableView";

const Chargers = () => {
  return (
    <div style={{ flex: 1, overflow: 'hidden' }}>
      <Header title="Chargers" />

      {/* <div className="Overview__ChartsWrapper">
        <ChartEnergySupplied />
        <ChartChargingTime />
        <ChartEarnings />
      </div> */}

      <TableView />
    </div>
  );
};

export default Chargers;
