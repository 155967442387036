/* tslint:disable */
/* eslint-disable */
/**
 * EVMatch OCPI API
 * This is the EVmatch OCPI dashboard api spec.  You can find out more about     Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/).
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: greg@gcdev.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Charger
 */
export interface Charger {
    /**
     * 
     * @type {number}
     * @memberof Charger
     */
    chargerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    stickerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    address_line1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    location_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    evse_id?: string | null;
    /**
     * 
     * @type {ChargerStatuses}
     * @memberof Charger
     */
    status?: ChargerStatuses;
    /**
     * 
     * @type {string}
     * @memberof Charger
     */
    statusTimestamp?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Charger
     */
    active?: boolean;
}
/**
 * See OCPI 2.2 §8.4.22 - Status enum
 * @export
 * @enum {string}
 */

export enum ChargerStatuses {
    Available = 'AVAILABLE',
    Blocked = 'BLOCKED',
    Charging = 'CHARGING',
    Inoperative = 'INOPERATIVE',
    Outoforder = 'OUTOFORDER',
    Planned = 'PLANNED',
    Removed = 'REMOVED',
    Reserved = 'RESERVED',
    Unknown = 'UNKNOWN'
}

/**
 * describes a OCPI charging session using CDRs
 * @export
 * @interface ChargingSession
 */
export interface ChargingSession {
    /**
     * 
     * @type {string}
     * @memberof ChargingSession
     */
    created_at?: string;
    /**
     * originates from `chargers.id`
     * @type {string}
     * @memberof ChargingSession
     */
    charger_id?: string;
    /**
     * originates from \'ocpi.cdrs.data.id\'
     * @type {string}
     * @memberof ChargingSession
     */
    cdr_id?: string;
    /**
     * energy in kWH with 3 fixed decimal places
     * @type {string}
     * @memberof ChargingSession
     */
    energy_kwh?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingSession
     */
    start_transaction_timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingSession
     */
    stop_transaction_timestamp?: string;
    /**
     * 
     * @type {ChargingSessionChargingDuration}
     * @memberof ChargingSession
     */
    charging_duration?: ChargingSessionChargingDuration;
    /**
     * originates from `ocpi.data.cdr_token.contract_id`
     * @type {string}
     * @memberof ChargingSession
     */
    vcr_id?: string;
    /**
     * originates from `ocpi.data.cdr_location.evse_uid`
     * @type {string}
     * @memberof ChargingSession
     */
    evse_uid?: string;
    /**
     * originates from `vehicle_charge_requests.host_price_cents`
     * @type {number}
     * @memberof ChargingSession
     */
    earnings_cents?: number;
    /**
     * originates from `chargers.stickerId`
     * @type {string}
     * @memberof ChargingSession
     */
    charger_sticker_id?: string;
    /**
     * originates from `chargers.name`
     * @type {string}
     * @memberof ChargingSession
     */
    charger_name?: string;
    /**
     * originates from `coalesce(charger.\"stickerId\" , charger.name , charger.id::text)`
     * @type {string}
     * @memberof ChargingSession
     */
    charger_derived_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingSession
     */
    charger_timezone?: string;
}
/**
 * 
 * @export
 * @interface ChargingSessionChargingDuration
 */
export interface ChargingSessionChargingDuration {
    /**
     * 
     * @type {number}
     * @memberof ChargingSessionChargingDuration
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingSessionChargingDuration
     */
    minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingSessionChargingDuration
     */
    seconds?: number;
}
/**
 * 
 * @export
 * @interface RemoteStartOrStopTransactionResponse
 */
export interface RemoteStartOrStopTransactionResponse {
    /**
     * 
     * @type {string}
     * @memberof RemoteStartOrStopTransactionResponse
     */
    status_message?: string;
    /**
     * 
     * @type {number}
     * @memberof RemoteStartOrStopTransactionResponse
     */
    status_code?: number;
    /**
     * 
     * @type {string}
     * @memberof RemoteStartOrStopTransactionResponse
     */
    timestamp?: string | null;
    /**
     * 
     * @type {RemoteStartOrStopTransactionResponseData}
     * @memberof RemoteStartOrStopTransactionResponse
     */
    data?: RemoteStartOrStopTransactionResponseData;
}
/**
 * 
 * @export
 * @interface RemoteStartOrStopTransactionResponseData
 */
export interface RemoteStartOrStopTransactionResponseData {
    /**
     * 
     * @type {RemoteStartOrStopTransactionResponseStatus}
     * @memberof RemoteStartOrStopTransactionResponseData
     */
    result?: RemoteStartOrStopTransactionResponseStatus;
    /**
     * 
     * @type {number}
     * @memberof RemoteStartOrStopTransactionResponseData
     */
    timeout?: number;
    /**
     * 
     * @type {string}
     * @memberof RemoteStartOrStopTransactionResponseData
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RemoteStartOrStopTransactionResponseStatus {
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED'
}


/**
 * AllChargePointsApi - axios parameter creator
 * @export
 */
export const AllChargePointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} evseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evseRemoteStart: async (evseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'evseId' is not null or undefined
            assertParamExists('evseRemoteStart', 'evseId', evseId)
            const localVarPath = `/chargers/v1/remote-start-transaction/{evseId}`
                .replace(`{${"evseId"}}`, encodeURIComponent(String(evseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} evseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evseRemoteStop: async (evseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'evseId' is not null or undefined
            assertParamExists('evseRemoteStop', 'evseId', evseId)
            const localVarPath = `/chargers/v1/remote-stop-transaction/{evseId}`
                .replace(`{${"evseId"}}`, encodeURIComponent(String(evseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a list of ChargePoints
         * @summary returns a list of ChargePoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllChargers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/chargers/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a list of ChargePoints and their statuses
         * @summary returns a list of ChargePoints and their statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllEvseChargers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/chargers/v1/charger-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieves individual charging sessions by charger_id
         * @summary retrieves individual charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {string} [timeframe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllSessions: async (chargingStart: string, chargingEnd: string, chargerId?: string, timeframe?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargingStart' is not null or undefined
            assertParamExists('listAllSessions', 'chargingStart', chargingStart)
            // verify required parameter 'chargingEnd' is not null or undefined
            assertParamExists('listAllSessions', 'chargingEnd', chargingEnd)
            const localVarPath = `/chargers/v1/charging-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chargerId !== undefined) {
                localVarQueryParameter['charger_id'] = chargerId;
            }

            if (chargingStart !== undefined) {
                localVarQueryParameter['charging_start'] = (chargingStart as any instanceof Date) ?
                    (chargingStart as any).toISOString() :
                    chargingStart;
            }

            if (chargingEnd !== undefined) {
                localVarQueryParameter['charging_end'] = (chargingEnd as any instanceof Date) ?
                    (chargingEnd as any).toISOString() :
                    chargingEnd;
            }

            if (timeframe !== undefined) {
                localVarQueryParameter['timeframe'] = timeframe;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllChargePointsApi - functional programming interface
 * @export
 */
export const AllChargePointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllChargePointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} evseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evseRemoteStart(evseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteStartOrStopTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evseRemoteStart(evseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} evseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evseRemoteStop(evseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evseRemoteStop(evseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns a list of ChargePoints
         * @summary returns a list of ChargePoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllChargers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Charger>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllChargers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns a list of ChargePoints and their statuses
         * @summary returns a list of ChargePoints and their statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllEvseChargers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Charger>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllEvseChargers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * retrieves individual charging sessions by charger_id
         * @summary retrieves individual charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {string} [timeframe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllSessions(chargingStart: string, chargingEnd: string, chargerId?: string, timeframe?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChargingSession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllSessions(chargingStart, chargingEnd, chargerId, timeframe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllChargePointsApi - factory interface
 * @export
 */
export const AllChargePointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllChargePointsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} evseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evseRemoteStart(evseId: string, options?: any): AxiosPromise<RemoteStartOrStopTransactionResponse> {
            return localVarFp.evseRemoteStart(evseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} evseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evseRemoteStop(evseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.evseRemoteStop(evseId, options).then((request) => request(axios, basePath));
        },
        /**
         * returns a list of ChargePoints
         * @summary returns a list of ChargePoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllChargers(options?: any): AxiosPromise<Array<Charger>> {
            return localVarFp.listAllChargers(options).then((request) => request(axios, basePath));
        },
        /**
         * returns a list of ChargePoints and their statuses
         * @summary returns a list of ChargePoints and their statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllEvseChargers(options?: any): AxiosPromise<Array<Charger>> {
            return localVarFp.listAllEvseChargers(options).then((request) => request(axios, basePath));
        },
        /**
         * retrieves individual charging sessions by charger_id
         * @summary retrieves individual charging sessions by charger_id
         * @param {string} chargingStart 
         * @param {string} chargingEnd 
         * @param {string} [chargerId] 
         * @param {string} [timeframe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllSessions(chargingStart: string, chargingEnd: string, chargerId?: string, timeframe?: string, options?: any): AxiosPromise<Array<ChargingSession>> {
            return localVarFp.listAllSessions(chargingStart, chargingEnd, chargerId, timeframe, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllChargePointsApi - object-oriented interface
 * @export
 * @class AllChargePointsApi
 * @extends {BaseAPI}
 */
export class AllChargePointsApi extends BaseAPI {
    /**
     * 
     * @param {string} evseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllChargePointsApi
     */
    public evseRemoteStart(evseId: string, options?: any) {
        return AllChargePointsApiFp(this.configuration).evseRemoteStart(evseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} evseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllChargePointsApi
     */
    public evseRemoteStop(evseId: string, options?: any) {
        return AllChargePointsApiFp(this.configuration).evseRemoteStop(evseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns a list of ChargePoints
     * @summary returns a list of ChargePoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllChargePointsApi
     */
    public listAllChargers(options?: any) {
        return AllChargePointsApiFp(this.configuration).listAllChargers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns a list of ChargePoints and their statuses
     * @summary returns a list of ChargePoints and their statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllChargePointsApi
     */
    public listAllEvseChargers(options?: any) {
        return AllChargePointsApiFp(this.configuration).listAllEvseChargers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieves individual charging sessions by charger_id
     * @summary retrieves individual charging sessions by charger_id
     * @param {string} chargingStart 
     * @param {string} chargingEnd 
     * @param {string} [chargerId] 
     * @param {string} [timeframe] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllChargePointsApi
     */
    public listAllSessions(chargingStart: string, chargingEnd: string, chargerId?: string, timeframe?: string, options?: any) {
        return AllChargePointsApiFp(this.configuration).listAllSessions(chargingStart, chargingEnd, chargerId, timeframe, options).then((request) => request(this.axios, this.basePath));
    }
}


