import styled from 'styled-components';
import { Spacing } from '../spacing';
import { SpacerOption, SpacerShape } from './constants';

/**
 * Adds shorthand spacing to any styled component
 *
 * EXAMPLE 1
 * Spacing.scale[4] margin:
 *   <Button m={4} />
 *
 */
export const shorthandSpacingCss = (props) =>
  Object.keys(props).map(
    (prop) =>
      SpacerShape[prop] &&
      `${SpacerShape[prop]}: ${Spacing.scale[props[prop]] || Spacing.component[props[prop]]};`
  );

export type ShorthandSpacingCssProps = { [key in keyof typeof SpacerShape]?: SpacerOption };

export type SpacerProps = ShorthandSpacingCssProps & {
  children?: React.ReactNode;
  as?: React.ElementType;
};

export const Spacer = styled('div')<SpacerProps>`
  ${shorthandSpacingCss}
`;
