import axios from './axios';

import { AllChargePointsApi, SpecificChargePointApi } from '../ocpp/api';
import { AllChargePointsApi as OcpiAllChargePointsApi} from '../ocpi/api';
import { getBasePath, getBasePathOcpi } from './config';

const BASE_PATH = getBasePath();
const BASE_PATH_OCPI = getBasePathOcpi();

export const allChargePointsApi = new AllChargePointsApi(undefined, BASE_PATH, axios);
export const specificChargePointApi = new SpecificChargePointApi(undefined, BASE_PATH, axios);
export const allOcpiChargePointsApi = new OcpiAllChargePointsApi(undefined, BASE_PATH_OCPI, axios);
