import React from 'react';
import * as CSS from 'csstype';
import { StyledFlex } from './styles';
import { FlexboxProps } from './constants';
import { ShorthandSpacingCssProps } from '../primitives/Spacer';

/*
    Modified from Chakra UI flexbox
    https://github.com/chakra-ui/chakra-ui/blob/main/packages/styled-system/src/config/flexbox.ts#L78
*/

interface FlexOptions {
  align?: FlexboxProps['alignItems'];
  justify?: FlexboxProps['justifyContent'];
  wrap?: FlexboxProps['flexWrap'];
  flexDirection?: FlexboxProps['flexDirection'];
  basis?: FlexboxProps['flexBasis'];
  grow?: FlexboxProps['flexGrow'];
  shrink?: FlexboxProps['flexShrink'];
}

interface FlexProps extends FlexOptions, CSS.Properties, ShorthandSpacingCssProps {
  className?: string;
  children?: React.ReactNode;
  as?: React.ElementType;
}

/**
 * React component used to create flexbox layouts.
 *
 * It renders a `div` with `display: flex` and
 * comes with helpful style shorthands:
 *
 * align, justify, wrap, flexDirection, basis, grow, shrink, flex
 */
const Flex = ({
  flexDirection,
  align,
  justify,
  wrap,
  basis,
  grow,
  shrink,
  className,
  as,
  children,
  ...rest
}: FlexProps) => {
  const styleProps = {
    display: 'flex',
    flexDirection,
    alignItems: align,
    justifyContent: justify,
    flexWrap: wrap,
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink,
    ...rest,
  };

  return (
    <StyledFlex className={className} css={styleProps} children={children} {...rest} as={as} />
  );
};

const Column = (props) => <Flex flexDirection="column" {...props} />;

Flex.Column = Column;

export { Flex };
