import React from "react";
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar, BarDatum } from '@nivo/bar';

type ChartType = "pie" | "bar";
export interface ChartData {
  id: string;
  label?: string;
  value: number;
  color?: string;
}

interface Props {
  type: ChartType;
  data: ChartData[] | BarDatum[];
  indexBy?: string;
  total?: number;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const PieChart = ({ data, total }) => {
  const CustomLayerComponent = (myProps) => (layerProps) => {
    const { centerX, centerY } = layerProps;
  
    return (
      <>
        <text
          x={centerX}
          y={centerY - 8}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {myProps}
        </text>
        <text
          x={centerX}
          y={centerY + 14}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "14px",
          }}
        >
          Total
        </text>
      </>
    );
  };
  return (
    <ResponsivePie
        data={data}
        margin={{ top: 15, right: 100, bottom: 15, left: 0 }}
        innerRadius={0.5}
        padAngle={0.7}
        colors={["#ff6801", "#666666", "#1b86c2", "#FF6900", "#e1f6fa", "#F96767", "#E9722E", "#F96767", "#ED722E"]}
        cornerRadius={8}
        activeOuterRadiusOffset={8}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLabel={e=> `${Math.round((e.value / total) * 100)}%`}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsRadiusOffset={0.55}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#fff"
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 70,
                translateY: 0,
                itemsSpacing: 10,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          CustomLayerComponent(total)
        ]}
    />
  )
};

const BarChart = ({ data, indexBy }) => {
  return (
    <ResponsiveBar
        data={data}
        margin={{ top: 15, right: 15, bottom: 30, left: 50 }}
        padding={0.3}
        indexBy={indexBy}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={["#ED722E", "#FF6900", "#FF8A48", "#FFB78E", "#E9722E", "#F96767"]}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        borderRadius={8}
        axisBottom={{
          tickSize: 0,
          tickPadding: data.length > 24 ? 5 : 10,
          tickRotation: data.length > 24 ? 45 : 0,
          legendOffset: data.length > 24 ? 45 : 0,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        tooltip={(point) => {
          return <div style={{ 
            display: "flex", 
            justifyContent: "center", 
            padding: 4, 
            background: "white", 
            fontWeight: "bold",
            color: "black",
            borderRadius: 4}}>
              {point.label.split('value -')[1]} - {point.data.value}
            </div>;
        }}
        label={d => `${formatter.format(d.value)}`}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="EVmatch Bar Chart"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in data: "+e.indexValue}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 8,
                
              }
            }
          }
        }}
    />
  )
}

export const Chart = ({ type, data, total, indexBy }: Props) => {
  const CHART_TYPE = {
    pie: <PieChart data={data || []} total={total} />,
    bar: <BarChart data={data || []} indexBy={indexBy} />,
  };
  return (
    data && Array.isArray(data) ? CHART_TYPE[type] : null
  );
};
