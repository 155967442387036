import styled from "styled-components";
import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";

import { blue } from "../../constants/colors";
import { Spacing, Text } from "../../components/common/design-system";
import { BREAKPOINTS } from "styles/breakpoints";

const StyledHeader = styled.div`
  margin-left: ${Spacing.scale["4"]};
  @media(min-width: ${BREAKPOINTS.SMALL}) {
    margin-left: 0px !important;
    text-align: center;
  }
`;

const Header = ({ title = "", activateCrumbs = false }) => {
  return (
    <StyledHeader>
      <div>
        {activateCrumbs ? (
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color={blue} href="/">
              Overview
            </Link>
            <Link underline="hover" color={blue} href="/">
              Core
            </Link>
            <Typography color="text.primary">Breadcrumbs</Typography>
          </Breadcrumbs>
        ) : null}

        <Text.Heading size="sm" mt={4}>{title}</Text.Heading>
      </div>
    </StyledHeader>
  );
};

export default Header;
