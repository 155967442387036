import * as React from 'react';
import { BaseLinkText } from './styles';
import { BaseTextProps } from './constants';

type LinkProps = BaseTextProps & React.ComponentPropsWithRef<'a'>;

const LinkText = React.forwardRef<HTMLAnchorElement, LinkProps>((props: LinkProps, ref) => (
  <BaseLinkText ref={ref as any} as="a" {...props} />
));

export default LinkText;
