import Cookies from 'js-cookie';
import { Charger, ChargerStatuses } from '../../../api/ocpp/api';
import { Charger as OcpiCharger } from '../../../api/ocpi/api';
import { allChargePointsApi, allOcpiChargePointsApi } from '../../../api/client';
import { UNKNOWN } from '../../../constants/index';

export interface Data {
  id: string;
  chargepoint: string;
  stickerId: string;
  evse_id: string;
  name: string;
  status: string;
  toggle: boolean;
}

type CombinedChargers = Charger & OcpiCharger;

export const TableData = async () => {
  const role = Cookies.get('role');
  try {
    const combinedChargers = [];
    const accessToken = Cookies.get('authKey');  
    const { data } = await allChargePointsApi.listAllChargers({ 
      withCredentials: true,
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      },
    });
    if (data.length) combinedChargers.push(...data);

    const { data: ocpiChargers } = await allOcpiChargePointsApi.listAllEvseChargers({ 
      withCredentials: true,
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      },
    });
    if (ocpiChargers.length) combinedChargers.push(...ocpiChargers);
    
    if (combinedChargers && combinedChargers.length) {
      return combinedChargers.filter(x => x.active).map((c: CombinedChargers) => {
        const id = String(c.chargerId) || UNKNOWN;
        const chargepoint = c.chargePointIdentity || c.evse_id || UNKNOWN;
        const evse_id = c.evse_id;
        const name = c.name || '--';
        const stickerId = c.stickerId || '--';
        const status = c.status || UNKNOWN;
        const toggle = c.status === ChargerStatuses.Online || c.status === ChargerStatuses.Charging;
    
        return { id, name, chargepoint, stickerId, status, toggle, evse_id };
      });
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}
