import { Tooltip } from "@mui/material";
import { BREAKPOINTS } from "../../components/common/breakpoints";
import { Colors, Spacing, Text } from "../../components/common/design-system";
import React from "react";
import styled from "styled-components";
import { InfoOutlined } from "@mui/icons-material";

type WidthTypes = "quarter" | "half" | "full";

interface Props {
  children: React.ReactNode;
  headerText?: String;
  width?: WidthTypes;
  smallPadding?: boolean;
  height?: number;
  noData?: boolean;
  loading?: boolean;
  tooltip?: string;
}

const StyledPanel = styled.div<{ width?: WidthTypes, smallPadding?: boolean, height?: number }>`
  position: relative;
  border-radius: 12px;
  background: ${Colors.background.white};
  padding: ${({smallPadding}) => smallPadding ? `${Spacing.scale[3]}` : `${Spacing.scale[6]} ${Spacing.scale[4]}`};
  width: 100%;
  box-shadow: 0px 2px 6px 0px #0D0A2C14;
  margin-bottom: 16px;
  box-sizing: border-box;

  @media(min-width: ${BREAKPOINTS.DESKTOP_MD}px) {
    height: calc(100% - 16px);
    width: ${({ width }) => {
      switch (width) {
        case "half":
            return "40%";
        case "quarter":
            return "20%";
        default:
          return "auto";
      }
    }}
  }
`;

export const Panel = ({ children, noData, headerText, width = "full", smallPadding, height, loading, tooltip }: Props) => {
  return (
    <StyledPanel width={width} smallPadding={smallPadding} height={height}>
      {headerText && (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          {tooltip ? (
            <Tooltip title={tooltip}>
              <div style={{ display: 'flex', marginRight: 15}}>
                <Text.Heading size="xxs">{headerText}</Text.Heading>
                <InfoOutlined />
              </div>
            </Tooltip>
        ) : (<Text.Heading size="xxs">{headerText}</Text.Heading>)}
          
        </div>
      )}
      <div>
      {loading ? (
        children
      ) : (
        <>
        {noData ? (
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", minWidth: 300, minHeight: 260}}>
            <Text>No information available</Text>
          </div>
        ): children }
        </>
      )}
      </div>
    </StyledPanel>
  );
};
