import { ChargingSession, ChargingSessionChargingDuration } from '../../../api/ocpp/api';
import { format } from 'date-fns';
export interface Data {
  charger_id?: number,
  charger_name?: string,
  charger_sticker_id?: string,
  start_transaction_timestamp?: string,
  stop_transaction_timestamp?: string,
  start_transaction_date?: string,
  start_transaction_time?: string,
  stop_transaction_date?: string,
  stop_transaction_time?: string,
  energy_kwh?: string,
  charging_duration?: ChargingSessionChargingDuration,
  earnings_cents?: number,
  charger_timezone?: string,
}

const formatNum = (num: number) => {
  if (num < 10) return `0${num}`;
  return num;
}

export const getTimezoneAbbreviation = (timezone: string) => {
  const date = new Date();
  const options: any = {
    timeZoneName: "short",
    timeZone: timezone,
  };

  const formatter = new Intl.DateTimeFormat([], options);
  const parts = formatter.formatToParts(date);

  // Find the 'timeZoneName' part from the formatted result
  const timeZoneAbbr = parts.find(part => part.type === "timeZoneName").value;

  return timeZoneAbbr;
}

function createData({ 
  charger_id,
  charger_name,
  charger_sticker_id,
  start_transaction_timestamp,
  stop_transaction_timestamp,
  charging_duration,
  energy_kwh,
  earnings_cents,
  charger_timezone 
}: Data) {
  return {
    charger_id,
    charger_name,
    charger_sticker_id,
    start_transaction_timestamp,
    stop_transaction_timestamp,
    start_transaction_date: format(new Date(start_transaction_timestamp),  'MM/dd/yyyy'),
    start_transaction_time: start_transaction_timestamp.replace(/^\d{2}\/\d{2}\/\d{4}\s*/, ''),
    stop_transaction_date: format(new Date(stop_transaction_timestamp), 'MM/dd/yyyy'),
    stop_transaction_time: stop_transaction_timestamp.replace(/^\d{2}\/\d{2}\/\d{4}\s*/, ''),
    charging_duration: `${charging_duration?.hours ? formatNum(charging_duration?.hours) : '00'}:${charging_duration?.minutes ? formatNum(charging_duration.minutes) : '00'}:${charging_duration?.seconds ? formatNum(charging_duration.seconds) : '00'}`,
    energy_kwh: parseFloat(Number(energy_kwh).toFixed(2)),
    earnings_cents: `$${(earnings_cents / 100).toFixed(2)}`,
    charger_timezone,
  };
}

interface TableDataProps {
  sessions: ChargingSession[];
}

export function TableData({ sessions = [] }: TableDataProps) {
  return sessions.map((s: ChargingSession) => {
   

    return createData(s);
  });
}
