import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Flex } from "../common/design-system";

import Navigation from "../Navigation";

const Container = styled(Flex)`
  display: flex;
`;

const Layout = ({ children }) => {
  const [hideNav, setHideNav] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
  }, [])

  const resize = () => {
    if (window.innerWidth <= 760) setHideNav(true)
  }

  return (
    <Container style={{ display: hideNav && 'block'}}>
      {
        !hideNav ?
          <div style={{ width: 272, backgroundColor: "#fff", borderRadius: 30 }}>
            <Navigation  />
          </div>
          : <Navigation />
      }
      {children}
    </Container>
  );
};

export default Layout;
