interface Scale {
  quarter: '2px';
  half: '4px';
  0: '0';
  1: '8px';
  2: '16px';
  3: '24px';
  4: '32px';
  5: '40px';
  6: '48px';
  7: '56px';
  8: '64px';
  9: '72px';
  10: '80px';
  11: '88px';
  12: '96px';
  13: '104px';
  14: '112px';
  15: '120px';
  16: '128px';
  17: '136px';
  18: '144px';
  19: '152px';
  20: '160px';
}

const scale: Scale = {
  quarter: '2px',
  half: '4px',
  0: '0',
  1: '8px',
  2: '16px',
  3: '24px',
  4: '32px',
  5: '40px',
  6: '48px',
  7: '56px',
  8: '64px',
  9: '72px',
  10: '80px',
  11: '88px',
  12: '96px',
  13: '104px',
  14: '112px',
  15: '120px',
  16: '128px',
  17: '136px',
  18: '144px',
  19: '152px',
  20: '160px',
};

interface SpacingShape {
  component: {
    sm: '12px';
    lg: '20px';
    auto: 'auto';
  };
  scale: Scale;
}

export const Spacing: SpacingShape = {
  component: {
    sm: '12px',
    lg: '20px',
    auto: 'auto',
  },
  scale,
};
