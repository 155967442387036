
import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from "styled-components";
import { Data, TableData } from './TableData';
import { EnhancedTableHead, getComparator, Order } from './TableHead';
import { EnhancedTableToolbar } from './TableToolbar';
import OrangeSwitch from '../../../components/OrangeSwitch';
import { SkeletonLoader } from '../../../components/SkeletonLoader';

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TableViewWrapper = styled.div`
  padding: 30px;
  width: 75vw;
`;

const TableLink = styled(Link)`
  color: /* Add your blue color here */;
  text-decoration: none;
`;

interface Props {
  chargers: Data[];
  loading?: boolean;
}

function TableView({ chargers, loading }: Props) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('charger_id');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const rows = TableData({ chargers });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.charger_name || n.charger_sticker_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const renderLoader = () =>  <TableRow
  role="checkbox"
  tabIndex={-1}
>
  <TableCell component="th" scope="row">
    <SkeletonLoader />
  </TableCell>
  <TableCell align="right" scope="row">
    <SkeletonLoader />
  </TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
  <TableCell align="right"><SkeletonLoader /></TableCell>
</TableRow>

  return (
    <TableViewWrapper>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
              />
              <TableBody>
                {loading ? (<>
                  {renderLoader()}
                  {renderLoader()}
                  {renderLoader()}
                  {renderLoader()}
                  {renderLoader()}

                </>) : (
                  <>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.charger_name || row.charger_sticker_id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row.charging_duration}`}
                          selected={isItemSelected}
                        >
                          <TableCell component="th" id={labelId} scope="row">
                            <TableLink to={`/reports/sessions?chargerIds=${row.charger_id}`}>
                              {row.charger_name || row.charger_sticker_id}
                            </TableLink>
                          </TableCell>
                          <TableCell align="right" scope="row">
                            {row.session_count}
                          </TableCell>
                          <TableCell align="right">{Number(row.energy_kwh).toFixed(2)}</TableCell>
                          <TableCell align="right">{row.charging_duration}</TableCell>
                          <TableCell align="right">{row.earnings_cents}</TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={
            <OrangeSwitch
              checked={dense}
              onChange={handleChangeDense}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Dense padding"
        />
      </Box>
    </TableViewWrapper>
  );
}

export default TableView;
